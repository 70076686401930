import React from 'react';
import Footer from '../components/Footer';
import RequestForm from '../components/homepage/RequestForm';

const HomePage = () => {
  return (
    <React.Fragment>
      <header className='container px-4 py-2'>
        <img src='assets/images/logo.svg' className='logo' alt='logo' />
        <span className='fs-4'>海狸师傅</span>
      </header>

      <div id='myCarousel' className='carousel slide' data-bs-ride='carousel'>
        <div className='carousel-indicators'>
          <button
            type='button'
            data-bs-target='#myCarousel'
            data-bs-slide-to='0'
            className='active'
            aria-current='true'
            aria-label='Slide 1'
          ></button>
          <button
            type='button'
            data-bs-target='#myCarousel'
            data-bs-slide-to='1'
            aria-label='Slide 2'
          ></button>
          <button
            type='button'
            data-bs-target='#myCarousel'
            data-bs-slide-to='2'
            aria-label='Slide 3'
          ></button>
        </div>
        <div className='carousel-inner'>
          <div className='carousel-item active'>
            <img
              className='img-fluid w-100'
              src='assets/images/slide1.png'
              aria-hidden='true'
              alt=''
            />
            <div className='container'>
              <div className='carousel-caption text-start'>
                <h4>您的满意, 我们的目标。 全年无休，竭诚为您服务。</h4>
              </div>
            </div>
          </div>
          <div className='carousel-item'>
            <img
              className='img-fluid w-100'
              src='assets/images/slide2.jpeg'
              aria-hidden='true'
              alt=''
            />
            <div className='container'>
              <div className='carousel-caption text-start'>
                <h4>全面专业技工，政府持牌，收费合理，极速匹配</h4>
              </div>
            </div>
          </div>
          <div className='carousel-item'>
            <img
              className='img-fluid w-100'
              src='assets/images/slide3.jpeg'
              aria-hidden='true'
              alt=''
            />
            <div className='container'>
              <div className='carousel-caption text-start'>
                <h4>精选技师解决您所有房屋相关的紧急疑难杂症</h4>
              </div>
            </div>
          </div>
        </div>
        <button
          className='carousel-control-prev'
          type='button'
          data-bs-target='#myCarousel'
          data-bs-slide='prev'
        >
          <span
            className='carousel-control-prev-icon'
            aria-hidden='true'
          ></span>
          <span className='visually-hidden'>Previous</span>
        </button>
        <button
          className='carousel-control-next'
          type='button'
          data-bs-target='#myCarousel'
          data-bs-slide='next'
        >
          <span
            className='carousel-control-next-icon'
            aria-hidden='true'
          ></span>
          <span className='visually-hidden'>Next</span>
        </button>
      </div>

      <div className='custom-renovation'>
        <div className='container px-4 py-8'>
          <h2 className='pb-2 border-bottom border-5 border-white'>旧屋翻修</h2>

          <div className='row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-3 pt-5'>
            <div className='col'>
              <div
                className='card card-cover h-100 overflow-hidden rounded-5'
                style={{ backgroundImage: "url('assets/images/1.png')" }}
              >
                <div className='d-flex flex-column h-100 p-5 pb-5 text-shadow-1'></div>
                <h2 className='pt-5 mt-5 display-6 lh-1 bottom-center-withbg'>
                  厨房装修
                </h2>
              </div>
            </div>

            <div className='col'>
              <div
                className='card card-cover h-100 overflow-hidden rounded-5'
                style={{ backgroundImage: "url('assets/images/2.png')" }}
                // style="background-image: url('assets/images/2.png');"
              >
                <div className='d-flex flex-column h-100 p-5 pb-5'></div>
                <h2 className='pt-5 mt-5 display-6 lh-1 bottom-center-withbg'>
                  窗户翻修
                </h2>
              </div>
            </div>

            <div className='col'>
              <div
                className='card card-cover h-100 overflow-hidden rounded-5'
                style={{ backgroundImage: "url('assets/images/3.png')" }}
              >
                <div className='d-flex flex-column h-100 p-5 pb-5 '></div>
                <h2 className='pt-5 mt-5 display-6 lh-1 bottom-center-withbg'>
                  洗手间翻修
                </h2>
              </div>
            </div>
          </div>

          <div className='row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-3'>
            <div className='col'>
              <div
                className='card card-cover h-100 overflow-hidden rounded-5'
                style={{ backgroundImage: "url('assets/images/4.png')" }}
              >
                <div className='d-flex flex-column h-100 p-5 pb-5 text-shadow-1'></div>
                <h2 className='pt-5 mt-5 display-6 lh-1 bottom-center-withbg'>
                  更换屋顶
                </h2>
              </div>
            </div>

            <div className='col'>
              <div
                className='card card-cover h-100 overflow-hidden rounded-5'
                style={{ backgroundImage: "url('assets/images/5.png')" }}
              >
                <div className='d-flex flex-column h-100 p-5 pb-5'></div>
                <h2 className='pt-5 mt-5 display-6 lh-1 bottom-center-withbg'>
                  露台装修
                </h2>
              </div>
            </div>

            <div className='col'>
              <div
                className='card card-cover h-100 overflow-hidden rounded-5'
                style={{ backgroundImage: "url('assets/images/6.png')" }}
              >
                <div className='d-flex flex-column h-100 p-5 pb-5 '></div>
                <h2 className='pt-5 mt-5 display-6 lh-1 bottom-center-withbg'>
                  围栏翻修
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='custom-renovation bg-white'>
        <div className='container px-4 py-8'>
          <h2 className='pb-2 border-bottom border-5'>紧急抢修</h2>

          <div className='row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-3 pt-5 custom-repair'>
            <div className='col'>
              <div className='card card-cover pt-3 h-100'>
                <img src='assets/images/icon/1.svg' alt='logo' />
                <h2 className='display-6 lh-1 bottom-center'>
                  洗手间堵/漏维修
                </h2>
              </div>
            </div>
            <div className='col'>
              <div className='card card-cover pt-3 h-100'>
                <img src='assets/images/icon/2.svg' alt='logo' />
                <h2 className='display-6 lh-1 bottom-center'>家用电器</h2>
              </div>
            </div>
            <div className='col'>
              <div className='card card-cover pt-3 h-100'>
                <img src='assets/images/icon/3.svg' alt='logo' />
                <h2 className='display-6 lh-1 bottom-center'>厨房堵/漏维修</h2>
              </div>
            </div>
          </div>

          <div className='row row-cols-1 row-cols-lg-3 align-items-stretch g-4 custom-repair'>
            <div className='col'>
              <div className='card card-cover pt-3 h-100'>
                <img src='assets/images/icon/4.svg' alt='logo' />
                <h2 className='display-6 lh-1 bottom-center'>厨用家电维修</h2>
              </div>
            </div>
            <div className='col'>
              <div className='card card-cover pt-3 h-100'>
                <img src='assets/images/icon/5.svg' alt='logo' />
                <h2 className='display-6 lh-1 bottom-center'>
                  冰箱，冰柜空调维修
                </h2>
              </div>
            </div>
            <div className='col'>
              <div className='card card-cover pt-3 h-100'>
                <img src='assets/images/icon/6.svg' alt='logo' />
                <h2 className='display-6 lh-1 bottom-center'>洗衣烘干机维修</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='custom-renovation '>
        <div className='row featurette align-items-end'>
          <div className='col-md-6'>
            <img
              className='img-fluid w-100'
              src='assets/images/contact.png'
              alt='contact'
            />
          </div>

          <div className='col-md-6'>
            <div className='contact-container'>
              <h2 className='featurette-heading contact'>
                专业维修服务，
                <br />
                行业首选、品质保证
              </h2>
              <RequestForm />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default HomePage;
