import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { messageActions } from '../../store/msg-slice';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createRequest } from '../../controller/create-request';

const RequestForm = () => {
  const dispatch = useDispatch();
  const schema = Yup.object({
    name: Yup.string().required(),
    phone: Yup.string().required(),
  });

  return (
    <div className='modal-body p-5 pt-0'>
      <h4 className='featurette-heading contact'>下单信息</h4>
      <Formik
        initialValues={{
          name: '',
          phone: '',
          category: '',
          description: '',
        }}
        validationSchema={schema}
        onSubmit={async (values, actions) => {
          // disable submit button
          actions.setSubmitting(true);
          console.log('values: ', values);
          // request to server
          try {
            console.log('进来了');
            const res = await createRequest(
              values.name,
              values.phone,
              values.category,
              values.description
            );
            console.log('res: ', res);
            if (res.code === 'Success') {
              actions.resetForm();
              // display success message modal
              const payload = {
                title: 'Submitted!',
                message: 'We will contact you within 24hrs! ',
              };
              dispatch(messageActions.showMessage(payload));
            } else {
              console.log('到这里哎');
              const payload = {
                title: 'Error!',
                message: 'Please try again...',
              };
              dispatch(messageActions.showMessage(payload));
            }
          } catch (err) {
            console.log('eeee到这里哎');
            // display err message modal to user
            const payload = {
              title: 'Error!',
              message: 'Please try again...',
            };
            dispatch(messageActions.showMessage(payload));
          }
          //  enable submit button again
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className=''>
            <div className='row'>
              <div className='form-floating mb-4 col-md-6'>
                <Field
                  name='name'
                  type='name'
                  className='form-control'
                  id='floatingInput'
                  placeholder='name@example.com'
                />
                <ErrMsgWrapper>
                  <ErrorMessage name='name' />
                </ErrMsgWrapper>
                <label style={{ marginLeft: '10px' }} htmlFor='floatingInput'>
                  名字
                </label>
              </div>

              <div className='form-floating mb-4 col-md-6'>
                <Field
                  name='phone'
                  type='phone'
                  className='form-control'
                  id='floatingInput'
                  placeholder='name@example.com'
                />
                <ErrMsgWrapper>
                  <ErrorMessage name='phone' />
                </ErrMsgWrapper>
                <label style={{ marginLeft: '10px' }} htmlFor='floatingInput'>
                  电话
                </label>
              </div>
            </div>

            {/* <div className='col-md-12 mb-4'>
            <div className='form-group'>
              <input
                name='date'
                type='text'
                className='form-control'
                value='选择日期'
                id='input'
              />
            </div>
          </div> */}

            <div className='form-floating mb-4'>
              <Field
                as='select'
                name='category'
                className='form-select form-control'
                id='floatingSelect'
                aria-label='Floating label select example'
              >
                <option>项目类别</option>
                <option value='装修'>装修</option>
                <option value='维修'>维修</option>
              </Field>
            </div>

            <div className='form-floating mb-4'>
              <Field
                name='description'
                as='textarea'
                className='form-control'
                placeholder='Leave a comment here'
                id='floatingTextarea2'
                style={{ height: '100px' }}
              />
              <label htmlFor='floatingTextarea2'>项目详情</label>
            </div>

            <div className='d-grid gap-2 col-6 mx-auto'>
              <button
                className='btn submit'
                type='submit'
                disabled={isSubmitting}
              >
                SUBMIT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RequestForm;

const ErrMsgWrapper = styled.div`
  color: red;
  text-align: center;
`;
