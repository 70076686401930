import './App.css';
import HomePage from './pages/HomePage';
import { useSelector } from 'react-redux';
import MessageModal from './components/message-modal/MessageModal';

function App() {
  const msgStatus = useSelector((state) => state.message.showModal);

  return (
    <div>
      {msgStatus && <MessageModal />}
      <HomePage />
    </div>
  );
}

export default App;
