import axios from 'axios';
import * as yup from 'yup';
import { generateSignedUri } from '../util/generate-signature';

export const createRequest = async (name, phone, category, description) => {
  const opts = {
    name: name,
    phone: phone,
    category: category,
    description: description,
  };
  const schema = yup.object().shape({
    name: yup.string().required(),
    phone: yup.string().required(),
  });
  try {
    schema.validateSync(opts);
  } catch (err) {
    return err;
  }

  const uri = `/v1/requests`;

  const signedUri = generateSignedUri(uri, {}, 'post', opts);

  try {
    const res = await axios.post(signedUri, opts);

    return res.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else if (err.request) {
      throw err.request;
    } else {
      throw err.message;
    }
  }
};
