import React from 'react';

const Footer = () => {
  return (
    <footer className='footer mt-auto py-3 footer-container'>
      <p className='float-end px-3'>
        <a href=' '>Back to top</a>
      </p>

      <div className='row featurette'>
        <div className='col-md-3'>
          <img
            src='assets/images/logoW.svg'
            style={{ width: '30%', marginLeft: '10%' }}
            alt='logo'
          />
        </div>
        <div className='col-md-5'>
          <div>CONTACT US </div>
          <div className='px-3 py-3'>autofairy.tech@gmail.com</div>
          <div className='px-3'>
            {' '}
            4380 Agar Dr.,Richmond,
            <br />
            BC V7B 1A3
          </div>
        </div>

        <div className='col-md-4 mt-4'>
          <div className='px-3'>
            <a href='/'>Privacy Policy</a>
          </div>
          <div className='px-3'>
            <a href='/'>Terms of Services</a>
          </div>
          <div className='px-3'>
            <a href='/'>Support</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
