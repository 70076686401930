import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import MD5 from 'crypto-js/md5';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import qs from 'qs';
import { pickBy } from 'lodash';

export const generateSignature = (uri, params, requestType, requestBody) => {
  let bodyBase64 = '';
  let strToSign = '';
  const md5 = MD5(JSON.stringify(requestBody)).toString();
  if (
    requestType === 'post' ||
    requestType === 'put' ||
    requestType === 'patch'
  ) {
    bodyBase64 = Base64.stringify(Utf8.parse(md5));
  }
  let cleanedObject = pickBy(params, (v) => v !== undefined && v !== null);

  const sortedObject = Object.keys(cleanedObject)
    .sort()
    .reduce((res, key) => {
      res[key] = cleanedObject[key];
      return res;
    }, {});

  if (requestType === 'get' || requestType === 'delete') {
    strToSign = uri + '\n' + qs.stringify(sortedObject);
  } else {
    strToSign = uri + '\n' + qs.stringify(sortedObject) + '\n' + bodyBase64;
  }
  const sn = Base64.stringify(
    hmacSHA256(strToSign, process.env.REACT_APP_WEB_APP_SECRET)
  );
  return sn;
};

export const generateSignedUri = (
  uri,
  realParams,
  requestType,
  requestBody
) => {
  let sn = '';
  let params = {
    ...realParams,
    ak: process.env.REACT_APP_WEB_APP_KEY,
    ts: moment().format('X'),
    nc: uuidv4(),
  };

  if (
    requestType === 'post' ||
    requestType === 'put' ||
    requestType === 'patch'
  ) {
    sn = generateSignature(uri, params, requestType, requestBody);
  } else {
    sn = generateSignature(uri, params, requestType);
  }

  params = { ...params, sn };

  const signedUri = `${process.env.REACT_APP_API}${uri}?${qs.stringify(
    params
  )}`;
  return signedUri;
};
