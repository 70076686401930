import { createSlice } from '@reduxjs/toolkit';

const messageSlice = createSlice({
  name: 'message',
  initialState: {
    showModal: false,
    title: '',
    message: '',
  },
  reducers: {
    showMessage(state, action) {
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.showModal = true;
    },
    hideMessage(state) {
      state.showModal = false;
    },
  },
});

export const messageActions = messageSlice.actions;
export const messageReducer = messageSlice.reducer;
