import React from 'react';
import styled from 'styled-components';

const CloseButton = (props) => {
  return <StyledCloseButton onClick={props.onClick} />;
};

export default CloseButton;

const StyledCloseButton = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    left: 23px;
    content: ' ';
    height: 18px;
    width: 2px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;
