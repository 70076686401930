import React from 'react';
import Modal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { messageActions } from '../../store/msg-slice';
// import { hideMsgModal } from '../../store/msg-actions';

const MessageModal = () => {
  const message = useSelector((state) => state.message);
  const dispatch = useDispatch();
  console.log('title: ', message.title);
  console.log('message: ', message.message);
  return (
    <Modal
      closeFunc={() => {
        dispatch(messageActions.hideMessage());
      }}
    >
      <h2>{message.title}</h2>
      <p>{message.message}</p>
    </Modal>
  );
};

export default MessageModal;
