import React from 'react';
import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import CloseButton from './CloseButton';

const Backdrop = (props) => {
  return <BackdropWrapper onClick={props.closeFunc} />;
};

const ModalOverlay = (props) => {
  return (
    <ModalOverlayWrapper>
      <CloseButton onClick={props.closeFunc} />
      <div className='content'>{props.children}</div>
    </ModalOverlayWrapper>
  );
};

const portalElement = document.getElementById('overlays');

const Modal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop closeFunc={props.closeFunc} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay closeFunc={props.closeFunc}>
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default Modal;

const BackdropWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
`;

const ModalOverlayWrapper = styled.div`
  position: fixed;
  top: 40vh;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 200ms ease-out forwards;

  /* @media (max-height: 568px) {
    top: 0%;
  } */

  @media (min-width: 768px) {
    /* width: 30rem; */
    width: 400px;
    left: calc(50% - 200px);
  }

  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
